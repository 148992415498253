import React, { useEffect, useRef } from 'react';
import { useLanguage } from '../../../languages/LanguageContext';
import { ExerciseState, GameMode } from '../../../types';
import getStyledGapText from './WriteInputField';
import Button from '../../baseComponents/Button';

const WriteInput: React.FC<{ 
  userResponse: string;
  setUserResponse: React.Dispatch<React.SetStateAction<string>>;
  showAnswerButtons: boolean;
  exerciseState: ExerciseState;
  solution: string;
  handleHintClick: () => void;
}> = ({ userResponse, setUserResponse, showAnswerButtons, exerciseState, solution, handleHintClick }) => {
  const { getTextLangFrom } = useLanguage();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserResponse(event.target.value);
  };

  useEffect(() => {
    if (userResponse === '') {
      inputRef.current?.focus();
    }
  }, [userResponse]);

  return (
    <div className="flex flex-col items-center gap-3">
      <div className="relative w-full">
        <input
          ref={inputRef}
          placeholder={getTextLangFrom('WritePage-input-placeholder')}
          value={userResponse}
          onChange={handleInputChange}
          className={`input-base w-full h-10 border-b border-border-tertiary focus:shadow-bottom-ring-brand text-lg font-medium cursor-text 
             ${showAnswerButtons ? 'opacity-10' : ''}`} // Todo remove opacity-10 and hide the text
          autoCapitalize="none"        // Disable auto-capitalization
          inputMode="text"             // Specify text input mode
          autoComplete="off"           // Disable autocomplete suggestions
          autoCorrect="off"            // Disable autocorrect
          spellCheck="false"           // Disable spell check
          pattern=".*"                 // Prevent some auto-suggestions
          enterKeyHint="go"            // Customize Enter key behavior
          disabled={showAnswerButtons}
        />
        <div className={`absolute top-0 left-0 h-10 flex items-center text-lg font-medium pointer-events-none 
          transition-opacity ${showAnswerButtons ? 'opacity-100' : 'opacity-0'}`}>
          {getStyledGapText(userResponse, solution, exerciseState, true)}
        </div>
        {!showAnswerButtons && (
          <Button 
            className="absolute bottom-2 right-0 flex"
            variant='secondary'
            onClick={() => handleHintClick()}
            size="sm"
            text={getTextLangFrom('word-hint')}
          />
        )}
      </div>
      <div className={`w-full h-14 flex flex-col items-start justify-start`}>
        {showAnswerButtons && getStyledGapText(solution, userResponse, exerciseState)}
      </div>
    </div>
  );
};

export default WriteInput;

