import React from 'react';
import Toast from '../../baseComponents/Toast';
import FlagIcon from '../../../assets/imagesApp/flag-02.svg';
import Modal from '../../baseComponents/Modal';
import { useAuth } from '../../../authentification/AuthContext';
import { useLanguage } from '../../../languages/LanguageContext';
import { deleteUserKnowledgeUnit } from '../../../api/Games';
import { get } from 'http';

interface FooterRemoveWordButtonProps {
    removeTranslation: (translationId: number) => void;
    translationId?: number;
}

const FooterRemoveWordButton: React.FC<FooterRemoveWordButtonProps> = ({ removeTranslation, translationId }) => {
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [isToastOpen, setIsToastOpen] = React.useState<boolean>(false);
    const { user } = useAuth();
    const { getTextLangFrom } = useLanguage();

  
      const handleRemoveTranslation = () => {
        console.log("user", user, "translationId", translationId);
        if (user && translationId) {
          deleteUserKnowledgeUnit(user.id, translationId);
          removeTranslation(translationId);
          setIsModalOpen(false);
          setIsToastOpen(true);
        } else {
          setIsModalOpen(false);
          console.error("User or translationId is undefined when trying to remove translation from exercises");
        }
      }

    return (
        <div>
            <button
                className="flex items-center justify-center p-1 text-text-tertiary gap-2"
                onClick={() => setIsModalOpen(true)}
            >
              <img src={FlagIcon}/>
            </button>
            <Modal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={getTextLangFrom('footerExerciseInfo-modal-title')}
                message={getTextLangFrom('footerExerciseInfo-modal-message')}
                confirmText={getTextLangFrom('word-back')}
                onConfirm={() => setIsModalOpen(false)}
                onCancel={() => handleRemoveTranslation()}
                cancelText={getTextLangFrom('word-remove')}
            />
            <Toast
                showToast={isToastOpen}
                setShowToast={setIsToastOpen}
                title={getTextLangFrom('footerExerciseInfo-toast-message')}
                buttonText={getTextLangFrom('word-hide')}
                onButtonClick={() => setIsToastOpen(false)}
            />
        </div>
    );
}

export default FooterRemoveWordButton;