import { BookExercise, Language, Message, PageUrl, TranslationTooltip, User } from "../../../types";
import showTooltipOnTextSelection from "./onTextSelection";
import showTooltiponWordClick from "./onWordClick";

// Now use these functions where necessary
const onBookMouseUp = async (
  event: React.MouseEvent<HTMLSpanElement>,
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>,
  langFrom: Language,
  langTo: Language,
  messages: Message[],
  getTextLangFrom: (key: string) => string,
  chatId: number,
  pageUrl: PageUrl = PageUrl.Book,
  userId?: number,
  part?: BookExercise | string,
) => {
  let tooltip: TranslationTooltip = {
    isVisible: false,
    bookExercise: undefined,
    googleTranslation: undefined,
    position: { top: undefined, bottom: undefined, left: undefined, right: undefined },
    userId: userId || 0,
    chatId: chatId,
    errorMessage: "",    
    isLoading: false,
  };

  const selection = window.getSelection();
  const isTextSelected = selection && selection.rangeCount > 0 && selection.toString().length > 0;

  // First check if there's selected text and a user
  if (isTextSelected && pageUrl !== PageUrl.Website) { // Temporarily disable the tooltip on the website
    await showTooltipOnTextSelection(selection, setTooltip, messages, tooltip, langFrom, langTo, getTextLangFrom, pageUrl);
  } else if (!isTextSelected && part) {
    // If no text is selected, check if a word was clicked
    await showTooltiponWordClick(event, part, setTooltip, tooltip, langFrom, langTo, pageUrl);
  } // any other case, hide the tooltip. See TranslationTooltip.tsx
};


export default onBookMouseUp;