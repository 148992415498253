import { useCallback, useEffect, useRef, useState } from 'react';
import { saveTranslationPreferences } from '../../../api/Messages';
import { PageUrl, TranslationTooltip } from '../../../types';
import Tag from '../../baseComponents/Tag';
import NewWordIcon from '../../../assets/imagesApp/new-word.svg';
import HardWordIcon from '../../../assets/imagesApp/hard-word.svg';
import ReviewWordIcon from '../../../assets/imagesApp/mastered-word.svg';
import Checkbox from '../../baseComponents/Checkbox';
import { useLanguage } from '../../../languages/LanguageContext';
import Toast from '../../baseComponents/Toast';
import { styled } from 'styled-components';
import { Loader } from '../../baseComponents/Loader';
import TranslationsDetailsDiv from '../TranslationDetails';

const StyledTooltip = styled.div<{ tooltip: TranslationTooltip }>`
  position: fixed;
  top: ${({ tooltip }) => tooltip.position.top ? `${tooltip.position.top}px` : 'auto'};
  bottom: ${({ tooltip }) => tooltip.position.bottom ? `${tooltip.position.bottom}px` : 'auto'};
  left: ${({ tooltip }) => tooltip.position.left ? `${tooltip.position.left}px` : 'auto'};
  right: ${({ tooltip }) => tooltip.position.right ? `${tooltip.position.right}px` : 'auto'};
`;

interface LearnPreferences {
  translationId: number;
  hasUku: boolean;
}


interface TranslationTooltipDivProps {
  tooltip: TranslationTooltip;
  learnWordPreferences: LearnPreferences[];
  setLearnWordPreferences: (learnWordPreferences: LearnPreferences[]) => void;
  pageUrl: PageUrl;
}

const TranslationTooltipDiv = ({ tooltip, learnWordPreferences, setLearnWordPreferences, pageUrl }: TranslationTooltipDivProps) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const { getTextLangFrom } = useLanguage();
  const [isChecked, setIsChecked] = useState<boolean | undefined>(undefined);
  const [isCheckboxClickable, setIsCheckboxClickable] = useState<boolean>(true);
  const [tagIcon, setTagIcon] = useState<string | undefined>(undefined);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [tagText, setTagText] = useState<string | undefined>(undefined);

  // const updateTag = (hasUku: boolean) => {
  //   console.log("hasUku", hasUku, "tooltip.bookExercise?.netCorrectScore", tooltip.bookExercise?.netCorrectScore)
  //   if (hasUku && !tooltip.bookExercise?.netCorrectScore) {
  //     setTagText("New word");
  //     setTagIcon(NewWordIcon);
  //   } else if (tooltip.bookExercise?.netCorrectScore && tooltip.bookExercise?.netCorrectScore < 0) {
  //     setTagText("Hard word");
  //     setTagIcon(HardWordIcon);
  //   } else if (tooltip.bookExercise?.netCorrectScore && tooltip.bookExercise.netCorrectScore > 1) {
  //     setTagText("Mastered word");
  //     setTagIcon(ReviewWordIcon);
  //   } else {
  //     setTagText(undefined);
  //     setTagIcon(undefined);
  //   }
  // }

  const getLearnWordPreference = useCallback(() => {
    // console.log("getLearnWordPreference", learnWordPreferences);
    const learnWordPreference = learnWordPreferences.find(
      (learnWordPreference: LearnPreferences) => learnWordPreference.translationId === tooltip.bookExercise?.translationId
    );
    // console.log("learnWordPreference", learnWordPreference);

    if (learnWordPreference) {
      return learnWordPreference.hasUku;
    } else {
      return tooltip.bookExercise?.netCorrectScore !== null;
    }
  }, [learnWordPreferences, tooltip.bookExercise]);

  // Update the tag and isChecked state when the tooltip is visible
  useEffect(() => {
    if (tooltip.isVisible && tooltip.bookExercise?.translationId !== undefined && isChecked === undefined) {
      const hasUku = getLearnWordPreference();
      // updateTag(hasUku)
      setIsChecked(hasUku || false);
    } else if (!tooltip.isVisible) {
      setIsChecked(undefined);
      setShowToast(false);
    }
  }, [tooltip, isChecked, getLearnWordPreference]);

  const onCheckboxClick = async () => {
    if (tooltip.bookExercise?.translationId && isCheckboxClickable) {
      // Prevent multiple clicks before the uku preferences are saved
      setIsCheckboxClickable(false);
      
      let hasUku = !isChecked;

      setIsChecked(hasUku);

      let newLearnWordPreferences = learnWordPreferences.filter(
        (learnWordPreference: LearnPreferences) => learnWordPreference.translationId !== tooltip.bookExercise?.translationId
      );
      newLearnWordPreferences.push({ translationId: tooltip.bookExercise.translationId, hasUku: hasUku });
      setLearnWordPreferences(newLearnWordPreferences);

      if (pageUrl !== PageUrl.Website) {
        await saveTranslationPreferences(tooltip.userId, tooltip.bookExercise.translationId, tooltip.chatId, hasUku);
      }

      setShowToast(true);
      setIsCheckboxClickable(true);
    } else {
      console.error("Word translation id is not defined");
    }
  };

  // Prevent the tooltip from closing when clicking on it
  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  // Tooltip is destroyed when not visible
  if (!tooltip.isVisible) {
    return null;
  }

  // console.log("tooltip", tooltip);

  return (
    <StyledTooltip tooltip={tooltip}
      className={`absolute bg-tooltip-bg py-2 rounded-md z-50 max-w-[350px] flex flex-col shadow-md border border-tooltip-border`}
      onMouseUp={handleMouseUp}
      ref={tooltipRef}
    >
      {tooltip.isLoading ? (
        <div className="px-2"><Loader /></div>
      ) : (
        <>
          <div className="flex flex-col gap-1 px-2">
            {/* {tagText && <Tag text={tagText} icon={tagIcon} variant='secondary' className='border-border-primary' />} */}
            <TranslationsDetailsDiv
              bookExercise={tooltip.bookExercise}
              googleTranslation={tooltip.googleTranslation}
              size="small"
              errorMessage={tooltip.errorMessage}
            />
          </div>
          {tooltip.bookExercise?.translationId && (
            <div className="border-t border-border-primary mt-1">
              <div className="flex gap-2 px-2 pt-2 items-center text-sm">
                {isChecked !== undefined && <Checkbox onChange={onCheckboxClick} checked={isChecked} size='md' variant='secondary' />}
                {getTextLangFrom('tooltip-want-to-learning')}
              </div>
            </div>
          )}
        </>
      )}
      <Toast
        title={isChecked ? getTextLangFrom('tooltip-toast-add-word-title') : getTextLangFrom('tooltip-toast-remove-word-title')}
        subtitle={isChecked ? getTextLangFrom('tooltip-toast-add-word-text') : getTextLangFrom('tooltip-toast-remove-word-text')}
        buttonText={getTextLangFrom('word-hide')}
        onButtonClick={() => setShowToast(false)}
        showToast={showToast}
        autoDismissTimeout={3000}
        setShowToast={setShowToast}
      />
    </StyledTooltip>
  );
};

export default TranslationTooltipDiv;