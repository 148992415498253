import React, { useEffect } from 'react';
import FooterControls from '../appComponents/FooterControls';
import { BookExercise, ExerciseState, getButtonVariantForExerciseState, MessageRole } from '../../types';
import ChevronRight from '../../assets/imagesApp/chevron-right-white.svg';
import { ResultsDisplay } from '../games/GameComponents/FooterExerciseInfo';
import Button from '../baseComponents/Button';

interface FooterControlsProps {
  bookExercises: BookExercise[];
  exerciseState: ExerciseState
  focusedExerciseIndex: number;
  messageRole: string;
  buttonText: string;
  handleClick: () => void;
  getTextLangFrom: (key: string) => string;
}

const BookFooterControls: React.FC<FooterControlsProps> = ({
  bookExercises,
  exerciseState,
  focusedExerciseIndex,
  messageRole,
  buttonText,
  handleClick,
  getTextLangFrom,
}) => {
  const [lastFocusedExerciseIndex, setLastFocusedExerciseIndex] = React.useState<number>(-1);
  const [footerExercise, setFooterExercise] = React.useState<BookExercise | undefined>(undefined);
  // console.log("focusedExerciseIndex", focusedExerciseIndex);

  useEffect(() => {
    // Save last focused exercise index
    if (focusedExerciseIndex >= 0) {
      setLastFocusedExerciseIndex(focusedExerciseIndex);
    } else if (focusedExerciseIndex === -2) {
      setLastFocusedExerciseIndex(-1);
    }

    // Update footer exercise
    const currentFooterExercise = isFocusedBookExercise(focusedExerciseIndex)
      ? bookExercises[focusedExerciseIndex] as BookExercise
      : lastFocusedExerciseIndex >= 0 && typeof bookExercises[lastFocusedExerciseIndex] === 'object'
      ? bookExercises[lastFocusedExerciseIndex] as BookExercise
      : undefined;
    setFooterExercise(currentFooterExercise);
  }, [focusedExerciseIndex, lastFocusedExerciseIndex]);

  // console.log("lastFocusedExerciseIndex", lastFocusedExerciseIndex, "focusedExerciseIndex", focusedExerciseIndex);
  const isFocusedBookExercise = (index: number): boolean => {
    return focusedExerciseIndex >= 0 && typeof bookExercises[index] === 'object'
  };

  const removeTranslation = (translationId: number) => {
    bookExercises = bookExercises.filter((bookExercise: BookExercise) => {
      return bookExercise.translationId !== translationId;
    });
  }

  return (
    <FooterControls isRelativePosition exerciseState={exerciseState}>
    <ResultsDisplay
      removeTranslation={removeTranslation}
      translationId={footerExercise?.translationId}
      lemmaLangTo={footerExercise?.lemmaLangTo}
      exampleLangTo={footerExercise?.exampleLangTo}
      exerciseState={exerciseState}
      messageRole={messageRole}
      pos={footerExercise?.pos}
      lemmaLangFrom={footerExercise?.lemmaLangFrom}
    />
      <div className="footer-controls-action-div">
        {(exerciseState === ExerciseState.Loading) ? (
          <Button
            variant="secondary"
            size="control"
            disabled
            onClick={() => {}}
            text={getTextLangFrom('word-loading-dots')}
          />
        ) : (focusedExerciseIndex < 0) ? (
          <Button 
            size="control"
            variant={getButtonVariantForExerciseState(exerciseState)}
            trailingIcon={ChevronRight}
            onClick={() => handleClick()}
            text={buttonText}
          />
        ) : (messageRole === MessageRole.BookTranslate) ? (
          <Button 
            size="control"
            variant={getButtonVariantForExerciseState(exerciseState)}
            trailingIcon={ChevronRight}
            onClick={() => handleClick()}
            text={buttonText}
          />
        ) : (
          <>ERROR</>
        )}
      </div>
    </FooterControls>
  );
};

export default BookFooterControls;
