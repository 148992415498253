import { terminateGame } from "../../../api/Games";
import { Message, GameState, GameMode, PageUrl, ExerciseType, Language, User } from "../../../types";
import { useNavigate } from 'react-router-dom';
import extractRecordsFromChapterMessages from "./exerciseRecordExtractor";


const terminateChapter = async (navigate: ReturnType<typeof useNavigate>, user: User, chatId: number, 
  chapterMessages: Message[], initialTime: Date, isChapterInterrupted: boolean,
) => {
  const gameMode = GameMode.Book;
  const { exerciseRecords, cardsForReviewExercise, accuracy } = extractRecordsFromChapterMessages(chapterMessages, user.langFrom, user.langTo);

  // console.log("Total exercises:", totalExercises, "Wrong answers:", wrongAnswers, "Accuracy:", (totalExercises - wrongAnswers) / totalExercises);
  terminateGame(user.id, gameMode, exerciseRecords, new Date(), chatId, false);

  // console.log("userId:", userId, "chatId:", chatId, "gameMode:", gameMode, "exerciseRecords:", exerciseRecords, "isChapterInterrupted:", false);

  // TEMPORARY FOR TESTING
  // cardsForReviewExercise = cardsForReviewExercise.slice(0, 1);
  
  // if it is the end of the card, navigate to the endgame page. Otherwise, navigate to the library
  if ( isChapterInterrupted ) {
    const gameState = {
      gameMode: gameMode,
      chatId: chatId,
      accuracy: accuracy,
      gameDuration: (new Date().getTime() - initialTime.getTime()) / 1000,
      nextExerciseType: cardsForReviewExercise.length > 0 ? ExerciseType.BookChapterReview : ExerciseType.BookGeneralReview,
      cards: cardsForReviewExercise,
      numberCardsToReview: cardsForReviewExercise.length,
    } as GameState;

    navigate(PageUrl.EndGame, { state: { gameState } });
  } else {
    navigate(PageUrl.Library);
  }
}


export default terminateChapter;