import React, { useEffect } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { EmptyStateProps } from '../../types';
import EmptyState from './EmptyState';
import PaginationControls from './PaginationControl';

interface ResponsiveHiddenColumn {
  columnId: string;
  screenSize?: 'sm' | 'md' | 'lg' | 'xl';
  hidden: boolean;
  alwaysHidden?: boolean;
}

interface TableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  idInitialSortKey?: string;
  isInitialSortAsc?: boolean;
  responsiveHiddenColumns?: ResponsiveHiddenColumn[];
  emptyStateProps?: EmptyStateProps;
}

const getCurrentScreenSize = (): 'sm' | 'md' | 'lg' | 'xl' => {
  const width = window.innerWidth;
  if (width < 640) return 'sm';
  if (width < 768) return 'md';
  if (width < 1024) return 'lg';
  return 'xl';
};

export const Table = <T,>({
  data,
  columns,
  sorting,
  setSorting,
  idInitialSortKey,
  isInitialSortAsc,
  responsiveHiddenColumns,
  emptyStateProps,
}: TableProps<T>) => {
  const [currentScreenSize, setCurrentScreenSize] = React.useState(getCurrentScreenSize());

  // Update current screen size on resize
  useEffect(() => {
    const handleResize = () => {
      setCurrentScreenSize(getCurrentScreenSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    state: { sorting },
    initialState: {
      pagination: { pageIndex: 0, pageSize: 20 },
    },
  });

  useEffect(() => {
    // Set default sorting
    if (idInitialSortKey && isInitialSortAsc !== undefined) {
      setSorting([{ id: idInitialSortKey, desc: !isInitialSortAsc }]);
    }
  }, [idInitialSortKey, isInitialSortAsc, setSorting]);

  // Function to check if a column should be hidden
  const isColumnHidden = (columnId: string): boolean => {
    return (
      responsiveHiddenColumns?.some(
        (col) =>
          col.columnId === columnId &&
          col.hidden &&
          (col.screenSize === currentScreenSize || col.alwaysHidden) // Check for screen size or always hidden
      ) ?? false
    );
  };

  return (
    <div className="table-and-pagination-wrapper">
      {data.length === 0 && emptyStateProps ? (
        <EmptyState {...emptyStateProps} />
      ) : (
        <>
          <table className="table">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    !isColumnHidden(header.id) && ( // Use isColumnHidden function here
                      <th key={header.id} colSpan={header.colSpan} scope="col"
                        style={{
                          width: header.getSize() !== 150 ? header.getSize() : undefined,
                          padding: '0px',
                        }}
                      >
                        <div 
                          className={
                            "table-header-bottom " +
                            (header.column.getCanSort() ? 'cursor-pointer select-none' : '')
                          }
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getIsSorted() === 'asc' &&  <ArrowUp className='table-icon-sorting' />}
                          {header.column.getIsSorted() === 'desc' && <ArrowDown className='table-icon-sorting' />}
                        </div>
                      </th>
                    )
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    !isColumnHidden(cell.column.id) && ( // Use isColumnHidden function here as well
                      <td key={cell.id} className="table-cell">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <PaginationControls table={table} />
        </>
      )}
    </div>
  );
}

export default Table;
