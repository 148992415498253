/* Types */

export type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error' | 'menuDesktop' | 'menuMobile';

/* Enums */

export enum MessageRole {
  User = 'user',
  Agent = 'agent',
  Error = 'error',
  Warning = 'warning',
  Title = 'title',
  Intro = 'intro',
  BookTranslate = 'book-translate'
}

export enum ExerciseType {
  BookChapterReview = 'book-chapter-review',
  BookGeneralReview = 'book-general-review',
  Vocabulary = 'vocabulary',
  NextChapter = 'next-chapter'
}

export enum GameMode {
  Book = 'book-mode',
  MultipleChoice = 'multiple-choice-mode',
  Flashcard = 'flashcard-mode',
  Write = 'write-mode'
}

export enum PageUrl {
  Website = '/',
  Login = '/login',
  SignUp = '/signup',
  PrivacyPolicy = '/privacy-policy',
  Library = '/library',
  OnboardingWelcome = '/onboarding-welcome',
  OnboardingLanguage = '/onboarding-language',
  OnboardingLevel = '/onboarding-level',
  Exercises = '/exercises',
  Flashcard = '/flashcard-mode',
  MultipleChoice = '/multiple-choice-mode',
  Write = '/write-mode',
  Book = '/book-mode',
  Profile = '/profile',
  Settings = '/settings',
  EndGame = '/endgame',
  StreakExtension = '/streak-extension',
  BookReviewExercises = '/book-review-exercises',
  BookUpload = '/book-upload',
  BookEdit = '/book-edit',
}


export enum Language {
  English = 'en',
  Italian = 'it',
  French = 'fr',
  German = 'de',
  Spanish = 'es'
}

export enum Gender {
  Masculine = 'masculine',
  Feminine = 'feminine',
  Neutral = 'neuter'
}

export enum CefrLevel {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2'
}

export enum Theme {
  Light = 'light',
  Dark = 'dark',
  Dusk = 'dark-dusk'
}

export enum UserStatus {
  FirstTimeUser = 'first-time-user',
  ActiveUser = 'active-user',
  NotFount = 'not-found',
  ServerError = 'server-error'
}

export enum ExerciseState {
  Correct = 'correct',
  AlmostCorrect = 'almost-correct',
  Wrong = 'wrong',
  Unanswered = 'unanswered',
  Loading = 'loading'
}

export enum GameTag {
  New = 'new',
  Review = 'review'
}


/* Interfaces */

export interface Book {
  id: number;
  chatId?: number;
  title: string;
  author: string;
  progress: number;
  coverImage?: any;
  uploaderId?: number;
  lastAccessed?: string;
};



export interface ChapterForNavigation {
  title: string;
  chapters: {
    numberWithinOriginalChapter: number;
    number: number;
    text: string;
  }[];
};


export interface BreadcrumbItem {
  icon?: string;
  text?: string;
  link?: string;
}

export interface ModalStepper {
  currentStep: number;
  totalSteps: number;
}

export interface EmptyStateProps {
  title: string;
  description?: string;
  ctaText?: string;
  onCtaClick?: () => void;
}

export interface Message {
  text: string;
  message_id?: number;
  messageRole: MessageRole;
  userId?: number;
  chatId?: number;
  agentId: number;
  sessionProgress?: number;
  bookExercises?: (string | BookExercise)[];
}


export interface User {
  id: number;
  username: string;
  langFrom: Language;
  langTo: Language;
  cefrLevel: CefrLevel;
  theme: Theme;
  status: UserStatus;
  numberStreakDays: number;
  last7dStreakDays: boolean[];
  isTesting?: boolean;
}


export interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  loginWithGoogle: (email: string) => Promise<void>;
  logout: () => void;
  updateLocalUser: (updatedUser: User) => void;
  updateLocalAndDbUser: (updatedUser: User) => Promise<void>;
  checkUserStatusByEmail: (email: string) => Promise<UserStatus>;
}

export interface GameState {
  gameMode: GameMode;
  nextExerciseType: ExerciseType;
  cards?: Card[];
  accuracy?: number;
  gameDuration?: number;
  chatId?: number;
  numberCardsToReview?: number;
}

export interface NavigationState {
  books: Book[];
  last7dStreakDays: boolean[];
  numberStreakDays: number;
  translationTableData: Translation[];
  profileData: ProfileData;
}

export interface BookState {
  initialMessages: Message[];
  chapterMessages: Message[];
  bookExercises: BookExercise[];
  chatId?: number;
  chapterNumber?: number;
}
export interface Card {
  translationId: number;
  lemmaLangFrom: string;
  exampleLangFrom?: string;
  lemmaLangTo: string;
  exampleLangTo?: string;
  score?: boolean;
  tag?: GameTag;
  pos?: string;
  genderLangFrom?: Gender;
  genderLangTo?: Gender;
  otherLemmasLangTo?: string[];
  userResponse?: string;
}

export interface ExerciseRecord {
  translationId: number;
  score: boolean;
  userResponse?: string;
}

export interface BookExercise {
  wordLangToId: number;
  translationId: number;
  lastSeen?: string;
  netCorrectScore?: number;
  cefrLevel?: CefrLevel;
  pos: string;
  lemmaLangFrom: string;
  genderLangFrom: Gender;
  numberLangFrom: string;
  pluralLangFrom?: string;
  otherLemmasLangFrom?: string[];
  exampleLangFrom?: string;
  lemmaLangTo: string;
  genderLangTo: Gender;
  numberLangTo: string;
  pluralLangTo?: string;
  exampleLangTo?: string;
  solution: string;
  userResponse: string;
  score?: boolean;
  exerciseState: ExerciseState;
  isExercise?: boolean;
}

export interface BookResponse {
  text: string;
  bookExercises: BookExercise[];
  title: string;
  chapterNumber: number;
  originalChapterNumber: number;
  chapterNumberWithinOriginalChapter: number;
  chatId: number;
  isBookCompleted?: boolean;
};

export interface TranslationTooltip {
  userId: number;
  bookExercise: BookExercise | undefined;
  googleTranslation: string | undefined;
  isVisible: boolean;
  position: {
    top: number | undefined;
    bottom: number | undefined;
    left: number | undefined;
    right: number | undefined;
  };
  chatId: number;
  errorMessage?: string;
  isLoading?: boolean;
}

export interface Translation {
  translationId: number;
  lemmaLangFrom: string;
  lemmaLangTo: string;
  lastSeen: string;
  score: number;
}

export interface ProfileData {
  numberWordsLearned: number;
  pagesReadPerDay: number[];
  translations: Translation[];
}

export interface OnboardingOption {
  code: string;
  text: string;
  icon?: string;
}

export interface OnboardingData {
  language?: Language;
  cefrLevel?: Extract<CefrLevel, CefrLevel.A1 | CefrLevel.A2 | CefrLevel.B1 | CefrLevel.B2>;
}


/* Functions */

export function gameModeToUrl(gameMode: GameMode): PageUrl {
  switch (gameMode) {
    case GameMode.Book:
      return PageUrl.Book;
    case GameMode.MultipleChoice:
      return PageUrl.MultipleChoice;
    case GameMode.Flashcard:
      return PageUrl.Flashcard;
    case GameMode.Write:
      return PageUrl.Write;
    default:
      console.error('Invalid game mode');
      return PageUrl.Library;
  }
}

export function getButtonVariantForExerciseState(state: ExerciseState): ButtonVariants {
  switch (state) {
    case ExerciseState.Correct:
      return 'success';
    case ExerciseState.AlmostCorrect:
      return 'warning';
    case ExerciseState.Wrong:
      return 'error';
    default:
      return 'primary';
  }
}
