import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authentification/AuthContext";
import { PageUrl, UserStatus } from "../../types";

const useNavigateToPlatform = () => {
  const { loginWithGoogle } = useAuth();
  const navigate = useNavigate();

  return async (email: string, userStatus: UserStatus) => {
    if (userStatus === UserStatus.FirstTimeUser || userStatus === UserStatus.ActiveUser) {
      await loginWithGoogle(email);
      if (userStatus === UserStatus.FirstTimeUser) {
        navigate(PageUrl.OnboardingWelcome);
      } else {
        navigate(PageUrl.Library);
      }
    } else {
      console.error("User status not recognized");
      throw new Error("User status not recognized");
    }
  };
};

export default useNavigateToPlatform;
