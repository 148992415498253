import React, { useState, useEffect } from 'react';
import {
  ColumnDef,
  SortingState,
} from '@tanstack/react-table';
import SearchBar from '../baseComponents/SearchBar';
import { NavigationState, PageUrl, Translation } from '../../types';
import { CellScore, CellTranslation, CellLastStudied } from './TableCellStyles';
import { useLanguage } from '../../languages/LanguageContext';
import Table from '../baseComponents/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import DropDownCustom from '../library/DropDownCustom';
import { deleteUserKnowledgeUnit } from '../../api/Games';
import { useAuth } from '../../authentification/AuthContext';

interface TranslationTableProps {
  translationTableData: Translation[];
  setTranslationTableData: React.Dispatch<React.SetStateAction<Translation[]>>;
}

const TranslationTable: React.FC<TranslationTableProps> = ({ translationTableData, setTranslationTableData }) => {
  const [filter, setFilter] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const { getTextLangFrom } = useLanguage();
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();

  const filteredData = React.useMemo(() => {
    return translationTableData.filter(translation =>
      (translation.lemmaLangFrom?.toLowerCase()?.includes(filter.toLowerCase()) || '') ||
      (translation.lemmaLangTo?.toLowerCase()?.includes(filter.toLowerCase()) || '')
    );
  }, [translationTableData, filter]);

  const handleOptionSelected = (option: string, id: number) => {
    // Remove the translation from db, table and location state
    user && deleteUserKnowledgeUnit(user.id, id);
    translationTableData = translationTableData.filter(translation => translation.translationId !== id);
    location.state.navigationState = {
      ...location.state?.navigationState,
      translationTableData: translationTableData,
    } as NavigationState;
    setTranslationTableData(translationTableData);
  }

  const columns = React.useMemo<ColumnDef<Translation>[]>(
    () => [
      {
        accessorKey: 'score',
        size: 100,
        cell: ({ row }) => (
          <CellScore score={row.original.score} key={row.id} />
        ),
        header: () => getTextLangFrom('word-score'),
      },
      {
        accessorKey: 'lemmaLangTo',
        header: () => getTextLangFrom('word-word'),
        cell: ({ row }) => (
          <CellTranslation
            lemmaLangFrom={row.original.lemmaLangFrom}
            lemmaLangTo={row.original.lemmaLangTo}
          />
        ),
      },
      {
        accessorKey: 'lastSeen',
        size: 160,
        cell: ({ row }) => (
          <CellLastStudied lastSeen={row.original.lastSeen} />
        ),
        header: getTextLangFrom('word-last-studied'),
      },
      {
        accessorKey: 'actions',
        size: 40,
        cell: ({ row }) => (
          <DropDownCustom
            options={['Delete']}
            handleOptionSelected={handleOptionSelected}
            id={row.original.translationId}
          />
        ),
        header: '',
      },
    ],
    [getTextLangFrom]
  );

  useEffect(() => {
    setSorting([{ id: 'lastSeen', desc: true }]);
  }, [translationTableData]);

  return (
    <div className="table-and-search-wrapper">
      <SearchBar filter={filter} setFilter={setFilter} />
      <Table
        data={filteredData}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        idInitialSortKey={'position'}
        isInitialSortAsc={true}
        responsiveHiddenColumns={[
          { columnId: 'lastSeen', screenSize: 'sm', hidden: true },
        ]}
        emptyStateProps={
          {
            title: getTextLangFrom('WordTable-empty-state-title'),
            description: getTextLangFrom('WordTable-empty-state-description'),
            ctaText: getTextLangFrom('WordTable-empty-state-ctaText'),
            onCtaClick: () => navigate(PageUrl.Library),
          }
        }
      />
    </div>
  );
}

export default TranslationTable;
