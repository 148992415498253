import { Card, ExerciseRecord, GameMode } from '../types';
import { apiUrl, fetchData } from './api';

export async function initializeVocabularyGame(userId: number, gameMode: GameMode): Promise<Card[]> {
  return await fetchData<Card[]>(`${apiUrl}/game/init`, 'POST', { userId, gameMode });
}

export function terminateGame(userId: number, gameMode: GameMode, gameRecords: Card[] | ExerciseRecord[], completionDate: Date, chatId?: number, isChapterInterrupted?: boolean): Promise<void> {
  return fetchData(`${apiUrl}/game/terminate`, 'POST', { userId, gameMode, gameRecords, completionDate, chatId, isChapterInterrupted }); // Don't await for the response
}

export async function deleteUserKnowledgeUnit(userId: number, translationId: number): Promise<boolean> {
  return await fetchData<boolean>(`${apiUrl}/game/remove-uku`, 'POST', { userId, translationId });
}