function getHint(solution: string, userResponse: string) {
  console.log("getHint", solution, userResponse);
  // Identify the string of equal characters at the beginning of the solution and userResponse
  let solutionIndex = 0;
  let userResponseIndex = 0;
  const minLength = Math.min(solution.length, userResponse.length);
  for (let i = 0; i < minLength; i++) {
    if (solution[i] === userResponse[i]) {
      solutionIndex++;
      userResponseIndex++;
    } else {
      break;
    }
  }
  // Identify the string of equal characters at the end of the solution and userResponse
  return solution.slice(0, solutionIndex + 1);
}

export default getHint;